import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { jsPDF } from 'jspdf';
import canvg from 'canvg';
import html2canvas from 'html2canvas';


let globalColorIndex = 0;
let runningWaste = 0;

export function drawCuttingPattern(stockLength, stockWidth, cuts, bladeSize, index, result) {

  if (index != 0) {
    return;
  }

  if (index === 0) {
    globalColorIndex = 0;
  }
  const devicePixelRatio = window.devicePixelRatio || 1;

  let widthOfContainer = (window.innerWidth * 0.5) / devicePixelRatio;
  let scaleLength = widthOfContainer / stockLength;
  let scaleWidth = widthOfContainer / stockWidth;
  let scaleFactor = Math.min(scaleLength, scaleWidth);
  scaleFactor *= 2;
  let waste = result.waste;
  runningWaste += Number(waste);

  const scaledStockLength = (stockLength * scaleFactor);
  const scaledStockWidth = (stockWidth * scaleFactor);

  let stockFill = '#9e9e9e';
  let strokeColor = '#000000';
  let strokeColorBorder = 'grey';
  let strokeWidth = 2;
  let strokeWidthBorder = 15;

  let labelOffset = 500;

  let svgContent = `<svg viewBox="0 0 ${scaledStockLength} ${scaledStockWidth + labelOffset}">`;


  let widthForLabel = (stockWidth - bladeSize).toFixed(2);
  let lengthForLabel = (stockLength - bladeSize).toFixed(2);

  const labelData = {
    Stock: `${widthForLabel} x ${lengthForLabel}`,
    Stock_Name: "",
    Material: result.material,
    Count: result.quantity,
    Waste_Area: waste,
  };

  let labelY = 50;
  const labelX = 20;
  const lineHeight = 60;

  Object.keys(labelData).forEach((key, index) => {
    let formattedKey = key.replace(/_/g, ' ');
    svgContent += `<text x="${labelX}" y="${labelY}" fill="#000000" font-size="40">${formattedKey}: ${labelData[key]}</text>`;
    labelY += lineHeight;
  });

  // Draw the stock item
  svgContent += `<rect x="0" y="${labelOffset}" width="${scaledStockLength}" height="${scaledStockWidth}" fill="${stockFill}" />`;

  cuts.forEach(cut => {

    let colour = colors[globalColorIndex % colors.length];
    globalColorIndex++;
    let contrastingColor = getContrastColor(colour);
    let rgbaColour = hexToRgba(colour, 0.35);

    let cutTag = cut.tag;

    const scaledCutLength = cut.Length * scaleFactor;
    const scaledCutWidth = cut.width * scaleFactor;
    const posX = cut.x * scaleFactor;
    const posY = cut.y * scaleFactor;

    svgContent += `<rect x="${posX}" y="${posY + labelOffset}" width="${scaledCutLength}" height="${scaledCutWidth}" fill="${rgbaColour}" stroke="${strokeColor}" stroke-width="${strokeWidth}" />`;

    const cutWidth = cut.Length;
    const textPosX = (cut.x + scaledCutLength / 2) * scaleFactor;
    const textPosY = (cut.y + 50 * scaleFactor);
    svgContent += `<text x="${textPosX}" y="${textPosY + labelOffset}" fill="${contrastingColor}" text-anchor="middle" font-size="${50 * 1}" font-weight="bold">${cutWidth}</text>`;



    const textHeightPosX = (cut.x + 50) * scaleFactor;
    const textHeightPosY = (cut.y + scaledCutWidth / 2) * scaleFactor;
    svgContent += `<text x="${textPosX}" y="${textPosY + (scaledCutWidth / 2) + labelOffset}" fill="${contrastingColor}" text-anchor="middle" alignment-baseline="middle" font-size="${50 * 1}" font-weight="bold">${cutTag}</text>`;

    svgContent += `<text x="${textHeightPosX - labelOffset}" y="${textHeightPosY}" fill="${contrastingColor}" text-anchor="middle" font-size="${50 * 1}" font-weight="bold" transform="rotate(-90, ${textHeightPosX}, ${textHeightPosY})">${cut.width}</text>`;
  });
  svgContent += `<rect x="0" y="0" width="${scaledStockLength}" height="${scaledStockWidth + labelOffset}" fill="none" stroke="${strokeColorBorder}" stroke-width="${strokeWidthBorder}" />`;
  svgContent += `</svg>`;
  return svgContent;
}


export function saveToPDF() {
  const pdf = new jsPDF('l', 'mm', 'a4'); // Initialize jsPDF in landscape orientation

  // First, handle the 'projectInfo' div
  const projectInfo = document.getElementById('projectInfo');
  if (projectInfo) {
    html2canvas(projectInfo).then(canvas => {
      // Ensure the image fits within the PDF page
      const imgWidth = pdf.internal.pageSize.getWidth();
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      const imgData = canvas.toDataURL('image/png');

      // Add the 'projectInfo' div as the first page
      pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);

      // Then handle the 'cutPage' divs
      processCutPages(pdf);
    });
  } else {
    // If 'projectInfo' div is not found, just process the 'cutPage' divs
    processCutPages(pdf);
  }
}

function processCutPages(pdf) {
  const element = document.getElementById('output');
  const children = element.querySelectorAll('[id^=cutPage]'); // Selects all nested divs with id starting with 'cutPage'

  Array.from(children).forEach((div, index) => {
    html2canvas(div).then(canvas => {
      // Ensure the image fits within the PDF page
      const imgWidth = pdf.internal.pageSize.getWidth();
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      const imgData = canvas.toDataURL('image/png');
      
      // Add a new page for each 'cutPage' div
      pdf.addPage(); // Add a new page for every div, including the first one

      // Adjust image dimensions to fit the page
      pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);

      // Save the PDF after the last div is processed
      if (index === children.length - 1) {
        pdf.save('output.pdf');
      }
    });
  });
}


export const handleCalculate = (cutsRequired, stockList, bladeSize, setCutResults, setGroupedCuts) => {
  const items = cutsRequired.flatMap((cut, index) =>
    Array.from({ length: cut.count }, (_, subIndex) => ({
      id: `${index}-${subIndex}`,
      xLen: (Number(cut.Length)) + bladeSize,
      yLen: (Number(cut.width)) + bladeSize,
      tag: cut.tag,
    }))
  );
  resetProjectCounts();
  const binXLen = stockList[0].Length + bladeSize;
  const binYLen = stockList[0].width + bladeSize;
  const packingSolution = ffdhFfBinPack(items, binXLen, binYLen);
  const results = packingSolution.map((bin, binIndex) => {
    console.log(bin);
    const cuts = bin.map(cut => {
      return {
        Length: Number(cut.Length) - bladeSize,
        width: Number(cut.width) - bladeSize,
        tag: cut.tag,
        x: cut.x,
        y: cut.y,
      };
    });
    const totalCutsArea = cuts.reduce((totalArea, cut) => totalArea + ((cut.Length + bladeSize) * (cut.width + bladeSize)), 0);
    const binArea = binXLen * binYLen;
    const waste = ((binArea - totalCutsArea) / 1000000).toFixed(2);
    return {
      binIndex: binIndex + 1,
      binLength: binXLen,
      binWidth: binYLen,
      cuts,
      waste,
    };
  });

  setCutResults(results);
  const calculatedGroupedCuts = groupCutlistDuplicated(results);
  setGroupedCuts(calculatedGroupedCuts);
};


export function calculateMaxStockLength(stockList) {
  let max = 0;
  for (let i = 0; i < stockList.length; i++) {
    if (stockList[i].Length > max) {
      max = stockList[i].Length;
    }
  }
  return max;
}

export function moveCutsLongerThanStock(cutsRequired, maxStockLength) {
  let cutsLongerThanStock = [];
  cutsRequired.forEach((cut, index) => {
    if (cut.Length > maxStockLength) {
      cutsLongerThanStock.push(cut);
    }
  });
  return cutsLongerThanStock;
}

export function rotateItem(item) {
  return { id: item.id, xLen: item.yLen, yLen: item.xLen };
}

export function ffdhShelfPack(items, binXLen) {
  const sortedItems = [...items].sort((a, b) => b.yLen - a.yLen);
  let shelves = [];
  sortedItems.forEach(item => {
    let placed = false;
    for (let shelf of shelves) {
      if (item.xLen <= shelf.remainingLength) {
        shelf.items.push(item);
        shelf.remainingLength -= item.xLen;
        placed = true;
        break;
      }
    }
    if (!placed) {
      shelves.push({
        remainingLength: binXLen - item.xLen,
        items: [item],
      });
    }
  });
  return shelves;
}

export function firstFit1D(shelves, binYLen) {
  let bins = [];
  shelves.forEach(shelf => {
    let placed = false;
    for (let bin of bins) {
      if (shelf.items[0].yLen <= bin.remainingwidth) {
        let shelfYPosition = binYLen - bin.remainingwidth;
        // let shelfYPosition = bin.shelves.reduce((totalwidth, currentShelf) => totalwidth + currentShelf.items[0].yLen, binYLen - bin.remainingwidth);
        shelf.items.forEach(item => {
          item.y = shelfYPosition;
          if (!('x' in item)) {
            item.x = 0; // Initialize X position for the first item in each shelf
          }
        });
        for (let i = 1; i < shelf.items.length; i++) {
          shelf.items[i].x = shelf.items[i - 1].x + shelf.items[i - 1].xLen; // Calculate X position for each subsequent item
        }
        bin.shelves.push(shelf);
        bin.remainingwidth -= shelf.items[0].yLen;
        placed = true;
        break;
      }
    }
    if (!placed) {
      // let shelfYPosition = binYLen - shelf.items[0].yLen; 
      let shelfYPosition = 0;
      shelf.items.forEach(item => {
        item.y = shelfYPosition; // Set Y position for each item in the shelf
        if (!('x' in item)) {
          item.x = 0; // Initialize X position for the first item in each shelf
        }
      });
      for (let i = 1; i < shelf.items.length; i++) {
        shelf.items[i].x = shelf.items[i - 1].x + shelf.items[i - 1].xLen; // Calculate X position for each subsequent item
      }
      bins.push({
        remainingwidth: binYLen - shelf.items[0].yLen,
        shelves: [shelf],
      });
    }
  });
  // Flatten the bins to get a simplified output of items with their bin index and positions
  return bins.map((bin, index) =>
    bin.shelves.flatMap(shelf =>
      shelf.items.map(item => ({
        id: item.id,
        binIndex: index,
        x: item.x,
        y: item.y,
        Length: item.xLen,
        width: item.yLen,
        tag: item.tag,
        // material: item.material,
      }))
    )
  );
}

export function ffdhFfBinPack(items, binXLen, binYLen) {
  let shelves = ffdhShelfPack(items, binXLen);
  let bins = firstFit1D(shelves, binYLen);
  return bins;
}



export function getContrastColor(hexColor) {
  // Convert hex color to RGB
  let r = parseInt(hexColor.substr(1, 2), 16);
  let g = parseInt(hexColor.substr(3, 2), 16);
  let b = parseInt(hexColor.substr(5, 2), 16);

  // Calculate relative luminance (Y)
  let luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

  // Use black or white depending on luminance
  return luminance > 0.5 ? '#000000' : '#FFFFFF';
}
export const colors = [
  '#00FFFF', // aqua
  '#0000FF', // blue
  '#FF00FF', // fuchsia
  '#008000', // green
  '#00FF00', // lime
  '#800000', // maroon
  '#000080', // navy
  '#808000', // olive
  '#800080', // purple
  '#FF0000', // red
  '#008080', // teal
  '#FFFF00', // yellow
];

export function hexToRgba(hex, opacity) {
  let r = 0, g = 0, b = 0;
  // 3 digits
  if (hex.length === 4) {
    r = parseInt(hex[1] + hex[1], 16);
    g = parseInt(hex[2] + hex[2], 16);
    b = parseInt(hex[3] + hex[3], 16);
  }
  // 6 digits
  else if (hex.length === 7) {
    r = parseInt(hex[1] + hex[2], 16);
    g = parseInt(hex[3] + hex[4], 16);
    b = parseInt(hex[5] + hex[6], 16);
  }
  return `rgba(${r},${g},${b},${opacity})`;
}


export function groupCutlistDuplicated(cutResults) {
  const groupedCuts = [];

  cutResults.forEach(result => {
    // Check if there's already a group with the same stock dimensions and cuts
    const existingGroupIndex = groupedCuts.findIndex(group => {
      if (group[0].binLength !== result.binLength || group[0].binWidth !== result.binWidth) {
        return false;
      }

      // Check if all cuts in the group are identical to the cuts in the current result
      return group.every(groupedResult =>
        groupedResult.cuts.length === result.cuts.length &&
        groupedResult.cuts.every((cut, index) =>
          cut.Length === result.cuts[index].Length &&
          cut.width === result.cuts[index].width
        )
      );
    });

    // If found, add the result to that group
    if (existingGroupIndex !== -1) {
      groupedCuts[existingGroupIndex].push(result);
    } else {
      // If not found, create a new group
      groupedCuts.push([result]);
    }
  });

  groupedCuts.forEach(group => {
    group.forEach(result => {
      result.quantity = group.length;
    });
  });


  return groupedCuts;
}



export function projectInformation(projectName, projectNumber, bladeSize) {
  // Calculate today's date in the desired format (YYYY-MM-DD)
  const today = new Date();
  const formattedDate = `${today.getFullYear()}-${String(today.getMonth() + 1).padStart(2, '0')}-${String(today.getDate()).padStart(2, '0')}`;

  return (
    <div id="projectInfo">
      <div style={{ margin: '20px', textAlign: 'left' }}>         
        <h3 style={{ fontSize: '20px', color: '#333', marginTop: '15px' }}>Project Information:</h3>
        <div>Project Name: {projectName}</div>
        <div>Project Number: {projectNumber}</div>
        <div>Date: {formattedDate}</div>
        <div>Blade Thickness (Kerf): {bladeSize}</div>
        <div>Total Number Of Stock Items: 0</div>
        <div>Total Amount Of Waste: {runningWaste.toFixed(2)}</div>
      </div>

    </div>
  );
};

export function resetProjectCounts() {
  runningWaste = 0;
}
