import React, { useState } from 'react';
import { MaterialReactTable } from 'material-react-table';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { groupCutlistDuplicated, resetProjectCounts, drawCuttingPattern, projectInformation, calculateMaxStockLength, moveCutsLongerThanStock, ffdhFfBinPack } from './Page-Cutting';
import { isDebug, twoDCutlist, twoDStockItems } from './Page-Cutting-debugItems';
import { handleFileInputChangeJSON } from './Page-Cutting-Import';



export const DialogAddStock = ({ open, onClose, onAdd, newStock, setNewStock }) => (
  <Dialog open={open} onClose={onClose}>
    <DialogTitle>Add Stock</DialogTitle>
    <DialogContent>
      <TextField
        autoFocus
        margin="dense"
        id="stock-width"
        label="Width"
        type="number"
        fullWidth
        variant="outlined"
        value={newStock.width}
        onChange={(e) => setNewStock({ ...newStock, width: Number(e.target.value) })}
      />
      <TextField
        margin="dense"
        id="stock-length"
        label="Length"
        type="number"
        fullWidth
        variant="outlined"
        value={newStock.Length}
        onChange={(e) => setNewStock({ ...newStock, Length: Number(e.target.value) })}
      />
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose}>Cancel</Button>
      <Button onClick={onAdd}>Add</Button>
    </DialogActions>
  </Dialog>
);

export const DialogAddCut = ({ open, onClose, onAdd, newCut, setNewCut }) => (
  <Dialog open={open} onClose={onClose}>
    <DialogTitle>Add Cut</DialogTitle>
    <DialogContent>
    <TextField
              autoFocus
              margin="dense"
              id="cut-width"
              label="Width"
              type="number"
              fullWidth
              variant="outlined"
              value={newCut.width}
              onChange={(e) => setNewCut({ ...newCut, width: Number(e.target.value) })}
            />
            <TextField
              margin="dense"
              id="cut-length"
              label="Length"
              type="number"
              fullWidth
              variant="outlined"
              value={newCut.Length}
              onChange={(e) => setNewCut({ ...newCut, Length: Number(e.target.value) })}
            />
            <TextField
              margin="dense"
              id="cut-depth"
              label="Depth/Thickness"
              type="number"
              fullWidth
              variant="outlined"
              value={newCut.thickness}
              onChange={(e) => setNewCut({ ...newCut, thickness: Number(e.target.value) })}
            />
            <TextField
              margin="dense"
              id="cut-quantity"
              label="Quantity"
              type="number"
              fullWidth
              variant="outlined"
              value={newCut.count}
              onChange={(e) => setNewCut({ ...newCut, count: Number(e.target.value) })}
            />
            <TextField
              margin="dense"
              id="cut-tag"
              label="Tag"
              type="text"
              fullWidth
              variant="outlined"
              value={newCut.tag} // Assuming 'tag' is the property for tag in your state
              onChange={(e) => setNewCut({ ...newCut, tag: e.target.value })}
            />
            <TextField
              margin="dense"
              id="cut-material"
              label="Material"
              type="text"
              fullWidth
              variant="outlined"
              value={newCut.material} // Assuming 'material' is the property for material in your state
              onChange={(e) => setNewCut({ ...newCut, material: e.target.value })}
            />
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose}>Cancel</Button>
      <Button onClick={onAdd}>Add</Button>
    </DialogActions>
  </Dialog>
);

export const stockListColumns = [
  { accessorKey: 'width', header: 'Width' },
  { accessorKey: 'Length', header: 'Length'},
  { accessorKey: 'thickness', header: 'Thickness' },
  { accessorKey: 'stockName', header: 'Stock Name' },
];


export const cutsRequiredColumns = (cutsRequired, setCutsRequired) => [
  {
    accessorKey: 'width',
    header: 'Width',
    Cell: (props) => (
      <input
        type="number"
        style={{
          border: 'none',
          background: 'transparent',
          width: '100%',
          textAlign: 'left',
        }}
        maxLength={5}
        onFocus={(e) => e.target.style.border = 'none'}
        onBlur={(e) => e.target.style.border = 'none'}
        value={props.row.original.width}
        onChange={(e) => {
          const updatedCuts = cutsRequired.map((cut, index) => {
            if (index === props.row.index) {
              return { ...cut, width: parseFloat(e.target.value) || 0 };
            }
            return cut;
          });
          setCutsRequired(updatedCuts);
        }}
      />
    ),
  },
  {
    accessorKey: 'Length',
    header: 'Length',
    Cell: (props) => (
      <input
        style={{
          border: 'none',
          background: 'transparent',
          Length: '100%',
          textAlign: 'left',
        }}
        maxLength={5}
        onFocus={(e) => e.target.style.border = 'none'}
        onBlur={(e) => e.target.style.border = 'none'}
        value={props.row.original.Length}
        onChange={(e) => {
          const updatedCuts = cutsRequired.map((cut, index) => {
            if (index === props.row.index) {
              return { ...cut, Length: parseFloat(e.target.value) || 0 };
            }
            return cut;
          });
          setCutsRequired(updatedCuts);
        }}
      />
    ),
  }, {
    accessorKey: 'thickness',
    header: 'Thickness',
    Cell: (props) => (
      <input
        style={{
          border: 'none',
          background: 'transparent',
          Length: '100%',
          textAlign: 'left',
        }}
        maxLength={5}
        onFocus={(e) => e.target.style.border = 'none'}
        onBlur={(e) => e.target.style.border = 'none'}
        value={props.row.original.thickness}
        onChange={(e) => {
          const updatedCuts = cutsRequired.map((cut, index) => {
            if (index === props.row.index) {
              return { ...cut, thickness: parseFloat(e.target.value) || 0 };
            }
            return cut;
          });
          setCutsRequired(updatedCuts);
        }}
      />
    ),
  },
  {
    accessorKey: 'count',
    header: 'Count',
    align: 'right',
    Cell: (props) => (
      <input
        type="number"
        style={{
          border: 'none',
          background: 'transparent',
          width: '100%',
          textAlign: 'left',
        }}
        maxLength={5}
        onFocus={(e) => e.target.style.border = 'none'}
        onBlur={(e) => e.target.style.border = 'none'} value={props.row.original.count}
        onChange={(e) => {
          const updatedCuts = cutsRequired.map((cut, index) => {
            if (index === props.row.index) {
              return { ...cut, count: parseInt(e.target.value) || 0 };
            }
            return cut;
          });
          setCutsRequired(updatedCuts);
        }}
      />
    ),
  },

  {
    accessorKey: 'tag',
    header: 'Tag',
    align: 'right',
    Cell: (props) => (
      <input
        type="text"
        style={{
          border: 'none',
          background: 'transparent',
          width: '100%',
          textAlign: 'left',
        }}
        maxLength={100}
        onFocus={(e) => e.target.style.border = 'none'}
        onBlur={(e) => e.target.style.border = 'none'}
        value={props.row.original.tag}
        onChange={(e) => {
          const updatedCuts = cutsRequired.map((cut, index) => {
            if (index === props.row.index) {
              return { ...cut, tag: e.target.value };
            }
            return cut;
          });
          setCutsRequired(updatedCuts);
        }}
      />
    ),
  },
  {
    accessorKey: 'material',
    header: 'Material',
    align: 'right',
    Cell: (props) => (
      <input
        type="text"
        style={{
          border: 'none',
          background: 'transparent',
          width: '100%',
          textAlign: 'left',
        }}
        maxLength={100}
        onFocus={(e) => e.target.style.border = 'none'}
        onBlur={(e) => e.target.style.border = 'none'}
        value={props.row.original.material}
        onChange={(e) => {
          const updatedCuts = cutsRequired.map((cut, index) => {
            if (index === props.row.index) {
              return { ...cut, material: e.target.value };
            }
            return cut;
          });
          setCutsRequired(updatedCuts);
        }}
      />
    ),
  },

];