import { resetProjectCounts,ffdhFfBinPack, groupCutlistDuplicated } from './Page-Cutting';

export function runPHP(data) {

  const itemsFromData = data.items;
  const stockFromData = data.Stock;
  const bladeSize = data.Kerf;
  const projectName = data.ProjectName;
  const projectNumber = data.ProjectNumber;

  const cutlistItems = convertItemsToCutlist(itemsFromData);
  const stockListItems = convertItemsToStockList(stockFromData);
 
  handleCalculatePHPCall(cutlistItems, stockListItems, bladeSize);

}

function convertItemsToCutlist(items) {
  return items.map(item => ({
    Length: item.Length,
    width: item.Width,
    thickness: item.Depth,
    count: item.Qty,
    material: item.Material,
    tag: item.Tag,
  }));
}

function convertItemsToStockList(items) {
  return items.map(item => ({
    Length: item.Length,
    width: item.Width,
  }));
}



const handleCalculatePHPCall = (cutsRequired, stockList, bladeSize, setCutResults, setGroupedCuts) => {
  const items = cutsRequired.flatMap((cut, index) =>
    Array.from({ length: cut.count }, (_, subIndex) => ({
      id: `${index}-${subIndex}`,
      xLen: (Number(cut.Length)) + bladeSize,
      yLen: (Number(cut.width)) + bladeSize,
      tag: cut.tag,
    }))
  );
  resetProjectCounts();
  const binXLen = stockList[0].Length + bladeSize;
  const binYLen = stockList[0].width + bladeSize;
  const packingSolution = ffdhFfBinPack(items, binXLen, binYLen);
  const results = packingSolution.map((bin, binIndex) => {
    console.log(bin);
    const cuts = bin.map(cut => {
      return {
        Length: Number(cut.Length) - bladeSize,
        width: Number(cut.width) - bladeSize,
        tag: cut.tag,
        x: cut.x,
        y: cut.y,
      };
    });
    const totalCutsArea = cuts.reduce((totalArea, cut) => totalArea + ((cut.Length + bladeSize) * (cut.width + bladeSize)), 0);
    const binArea = binXLen * binYLen;
    const waste = ((binArea - totalCutsArea) / 1000000).toFixed(2);
    return {
      binIndex: binIndex + 1,
      binLength: binXLen,
      binWidth: binYLen,
      cuts,
      waste,
    };
  });

  const calculatedGroupedCuts = groupCutlistDuplicated(results);

  // console.log (results);
  console.log(calculatedGroupedCuts);
};