import React, { useState } from 'react';
import './App.css';
import { MaterialReactTable } from 'material-react-table';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { saveToPDF, handleCalculate, groupCutlistDuplicated, resetProjectCounts, drawCuttingPattern, projectInformation, calculateMaxStockLength, moveCutsLongerThanStock, ffdhFfBinPack } from './Page-Cutting';
import { stockListColumns, cutsRequiredColumns, DialogAddCut, DialogAddStock } from './Page-Cutting-Tables';
import { isDebug, twoDCutlist, twoDStockItems } from './Page-Cutting-debugItems';
import { handleFileInputChangeJSON } from './Page-Cutting-Import';
import { ProjectDetails } from './Page-Cutting-ProjectLevel'

const Cutting2D = () => {
  const [bladeSize, setBladeSize] = useState(4);
  const [cutResults, setCutResults] = useState([]);
  const [projectName, setProjectName] = useState('');
  const [projectNumber, setProjectNumber] = useState('');
  const [stockList, setStockList] = useState(twoDStockItems);
  const [cutsRequired, setCutsRequired] = useState(twoDCutlist);
  const [isAddStockOpen, setIsAddStockOpen] = useState(false);
  const [isAddCutOpen, setIsAddCutOpen] = useState(false);
  const [newStock, setNewStock] = useState({ width: '', Length: '' });
  const [newCut, setNewCut] = useState({ Length: '', width: '', count: '' });
  const [groupedCuts, setGroupedCuts] = useState([]);

  const handleOpenAddStock = () => setIsAddStockOpen(true);
  const handleCloseAddStock = () => setIsAddStockOpen(false);
  const handleOpenAddCut = () => setIsAddCutOpen(true);
  const handleCloseAddCut = () => setIsAddCutOpen(false);

  const handleAddStock = () => {
    if (newStock.Length && newStock.width) {
      setStockList([...stockList, newStock]);
      setNewStock({ width: '', Length: '' });
      handleCloseAddStock();
    } else {
      handleOpenAddStock();
    }
  };
  const handleAddCut = () => {
    if (newCut.Length && newCut.width && newCut.count) {
      setCutsRequired([...cutsRequired, newCut]);
      setNewCut({ Length: '', width: '', count: '' });
      handleCloseAddCut();
    } else {
      handleOpenAddCut();
    }
  };


  calculateMaxStockLength(stockList);
  moveCutsLongerThanStock(cutsRequired);


  const handleSaveAsPDF = () => {
    const svgContent = groupedCuts.map((group, groupIndex) =>
      group.map((result, index) =>
        drawCuttingPattern(result.binLength, result.binWidth, result.cuts, bladeSize, index, result, result.quantity)
      ).join('')
    ).join('');
    saveToPDF(svgContent);
  };


  return (
    <div style={{ fontFamily: 'Roboto, Arial, sans-serif', fontSize: '18px' }}>
      <h1 style={{ fontSize: '24px', marginTop: '15px' }}>Board Cutting</h1>







      <DialogAddStock open={isAddStockOpen} onClose={handleCloseAddStock} onAdd={handleAddStock} newStock={newStock} setNewStock={setNewStock} />
      <DialogAddCut open={isAddCutOpen} onClose={handleCloseAddCut} onAdd={handleAddCut} newCut={newCut} setNewCut={setNewCut} />

      <div style={{ width: '50%', float: 'left', marginTop: '15px' }}>
        <input
          type="file"
          accept=".json"
          onChange={(event) =>
            handleFileInputChangeJSON(
              event,
              setStockList,
              setCutsRequired,
              setProjectNumber,
              setProjectName,
              setBladeSize
            )
          }
          style={{ display: 'none' }}
          id="jsonFileInput"
        />

        <label htmlFor="jsonFileInput">
          <Button component="span" variant="contained" color="primary">
            Import from JSON
          </Button>
        </label>

        <label style={{ marginLeft: '10px' }} htmlFor="jsonFileInput">
          <Button component="span" variant="contained" color="primary">
            Import from Excel
          </Button>
        </label>


        <ProjectDetails
          projectName={projectName}
          setProjectName={setProjectName}
          projectNumber={projectNumber}
          setProjectNumber={setProjectNumber}
          bladeSize={bladeSize}
          setBladeSize={setBladeSize}
        />

        <div style={{ marginTop: '25px' }}  >
          <h3 style={{ fontSize: '20px', color: '#333', marginTop: '15px' }}>Stock List:</h3>
          <Box maxWidth="calc(100% - 20px)">
            <MaterialReactTable
              columns={stockListColumns}
              data={stockList}
              initialState={{
                pagination: {
                  pageSize: 100,
                  pageIndex: 0,
                },
                density: 'compact'
              }}
              enableEditing={true}
              enableRowActions={true}
              renderRowActions={({ row }) => (
                <Button
                  size="small"
                  onClick={() => {
                    const newStockList = stockList.filter((_, index) => index !== row.index);
                    setStockList(newStockList);
                  }}
                >
                  Remove
                </Button>
              )}
            />
          </Box>
          <Button onClick={handleOpenAddStock}>Add Stock</Button>
        </div>






        <div>
          <h3 style={{ fontSize: '20px', color: '#333', marginTop: '15px' }}>Cut List:</h3>
          <Box maxWidth="calc(100% - 20px)">



            <MaterialReactTable
              initialState={{
                pagination: {
                  pageSize: 100,
                  pageIndex: 0,
                },
                density: 'compact',
              }}
              enableEditing={true}
              columns={cutsRequiredColumns(cutsRequired, setCutsRequired)}
              data={cutsRequired}
              enableRowActions={true}
              renderRowActions={({ row }) => (
                <Button
                  size="small"
                  onClick={() => {
                    const newCutsRequired = cutsRequired.filter((_, index) => index !== row.index);
                    setCutsRequired(newCutsRequired);
                  }}
                >
                  Remove
                </Button>
              )}
            />
          </Box>
          <Button onClick={handleAddCut} style={{ marginTop: '10px' }}>
            Add Cut
          </Button>
        </div>
      </div>



      <div style={{ width: '50%', float: 'right', marginTop: '20px' }}>
        <Button onClick={() => handleCalculate(cutsRequired, stockList, bladeSize, setCutResults, setGroupedCuts)} variant="contained" >
          Calculate
        </Button>

        <Button variant="contained" style={{ marginLeft: '10px' }} onClick={handleSaveAsPDF}>
          Save as PDF
        </Button>

        { }


        <div id='output' style={{ marginRight: '10px' }}>
          {projectInformation(projectName, projectNumber, bladeSize)}

          {groupedCuts.map((group, groupIndex) => (
            <div id={`cutPage${groupIndex + 1}`} key={groupIndex}>
              {group.map((result, index) => (
                <div key={index}>
                  <div dangerouslySetInnerHTML={{ __html: drawCuttingPattern(result.binLength, result.binWidth, result.cuts, bladeSize, index, result, result.quantity) }} />
                </div>
              ))}
            </div>
          ))}

        </div>


      </div>





    </div>
  );
};
export default Cutting2D;