import React, { useState } from 'react';
import { runPHP } from './Page-Cutting-export.js';

function TestPHPCall() {
  const [response, setResponse] = useState('');
  const [testData, setTestData] = useState(null);

  const handleTestCall = () => {
    if (testData) {
      runPHP(testData);
    } else {
      alert('Please upload test data JSON file first.');
    }
  };

  const handleFileInputChangeJSON = (event) => {
    const fileReader = new FileReader();
    const file = event.target.files[0];

    fileReader.readAsText(file, "UTF-8");
    fileReader.onload = e => {
      const content = e.target.result;
      try {
        const jsonData = JSON.parse(content);
        setTestData(jsonData);
      } catch (error) {
        alert('Error parsing JSON file');
        console.error('Error reading JSON file:', error);
      }
    };
  };

  return (
    <div>
      <h2>Test PHP Call</h2>
      <input
        type="file"
        accept=".json"
        onChange={handleFileInputChangeJSON}
        style={{ marginBottom: '20px' }}
      />
      <button onClick={handleTestCall}>Make Test Call</button>
      <div>
        <h3>Response:</h3>
        <pre>{response}</pre>
      </div>
    </div>
  );
}

export default TestPHPCall;
