// PHPDataFetcher.js
import React, { useState, useEffect } from 'react';

function PHPDataFetcher() {
  const [data, setData] = useState('');

  useEffect(() => {
    fetch('https://smartcutlist.com/run2DCutting.php')  
      .then(response => response.json())
      .then(data => setData(data.message))
      .catch(error => console.error('Error fetching data: ', error));
  }, []);

  return (
    <div>
      <h2>Data from PHP:</h2>
      <p>{data}</p>
    </div>
  );
}

export default PHPDataFetcher;
