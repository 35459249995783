// App.js
import React from 'react';
import './App.css';
import CuttingLinear from './Page-Cutting1D';
import Cutting2D from './Page-Cutting2D';
import PHPTestCall from './Page-TestPHP';
import { BrowserRouter as Router, Route, Routes, useLocation, Navigate } from 'react-router-dom';
import Sidebar from './App-SideBar';
import Home from './App-Home';
import PHPDataFetcher from './PHPDataFetcher';  

function App() {
  return (
    <Router>
      <div className="App">
        <Sidebar />
        <div className="content">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/Cutting1D" element={<CuttingLinear />} />
            <Route path="/Cutting2D" element={<Cutting2D />} />
            <Route path="/PHPTestCall" element={<PHPTestCall />} />
            <Route path="/PHPData" element={<PHPDataFetcher />} /> 
            <Route path="*" element={<NotFoundRedirect />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

const NotFoundRedirect = () => {
  const location = useLocation();

  if (location.pathname !== "/") {
    return <Navigate to="/" />;
  }

  return null;
};

export default App;