import React from 'react';
import { Box, TextField } from '@mui/material';

export const ProjectDetails = ({ projectName, setProjectName, projectNumber, setProjectNumber, bladeSize, setBladeSize }) => {
  return (
    <Box marginTop="15px" maxWidth="calc(100% - 20px)">
      <div align="left">
        <label>Project Name:</label>
        <TextField
          fullWidth
          value={projectName}
          onChange={(e) => setProjectName(e.target.value)}
        />
      </div>
      <div align="left">
        <label>Project Number:</label>
        <TextField
          fullWidth
          value={projectNumber}
          onChange={(e) => setProjectNumber(e.target.value)}
        />
      </div>
      <div align="left">
        <label>Blade Thickness (Kerf):</label>
        <TextField
          fullWidth
          type="number"
          value={bladeSize}
          onChange={(e) => {
            const newValue = parseFloat(e.target.value);
            if (newValue >= 0.000 && newValue <= 20) {
              setBladeSize(newValue);
            } else if (newValue < 0.0001) {
              setBladeSize(0.0001);
            } else if (newValue > 20) {
              setBladeSize(20);
            }
          }}
        />
      </div>
    </Box>
  );
};

export default ProjectDetails;
