
export const isDebug = true;

export const twoDStockItems = [
  { Length: 2400, width: 1200 },
];

export const twoDCutlist = [
  { Length: 2400, width: 1200, count: 3, tag: '1' },
  { Length: 1200, width: 1200, count: 2, tag: '2' },
  { Length: 1198, width: 1200, count: 2, tag: '3' },
  { Length: 797, width: 1200, count: 3, tag: '4' },
  { Length: 2400, width: 600, count: 2, tag: '5' },
  { Length: 2400, width: 598, count: 2, tag: '6' },
];