export const handleFileInputChangeJSON = (
  event,
  setStockList,
  setCutsRequired,
  setProjectNumber,
  setProjectName,
  setBladeSize
) => {
  const file = event.target.files[0];
  const reader = new FileReader();
  reader.onload = (e) => {
    try {
      const jsonData = JSON.parse(e.target.result);
      const { StockLength, StockWidth, Kerf, items, ProjectName, ProjectNumber } = jsonData;
      const cutsRequiredData = items.map(item => ({
        Length: item.Length,
        width: item.Width,
        thickness: item.Depth,
        count: item.Qty,
        material: item.Material,
        tag: item.Tag,
      }));
      setStockList([{ Length: StockLength, width: StockWidth }]);
      setCutsRequired(cutsRequiredData);
      setProjectNumber(ProjectNumber);
      setProjectName(ProjectName);
      setBladeSize(Kerf);
    } catch (error) {
      console.error('Error reading file:', error);
    }
  };
  reader.readAsText(file);
};
