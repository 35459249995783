import React from 'react';
import {
  CDBSidebar,
  CDBSidebarContent,
  CDBSidebarFooter,
  CDBSidebarHeader,
  CDBSidebarMenu,
  CDBSidebarMenuItem,
} from 'cdbreact';
import { NavLink } from 'react-router-dom';
import { IconHome, IconBoard, IconLinear } from './App-Icons'; // Import your custom icons

const Sidebar = () => {
  return (
    <div style={{ display: 'flex', height: '100vh', overflow: 'scroll initial' }}>
      <CDBSidebar textColor="#fff" backgroundColor="#333">
        <CDBSidebarHeader prefix={<i className="fa fa-bars fa-large"></i>}>
          <a href="/" className="text-decoration-none" style={{ color: 'inherit' }}>
            Menu
          </a>
        </CDBSidebarHeader>

        <CDBSidebarContent className="sidebar-content">
          <CDBSidebarMenu>
            <NavLink to="/" activeClassName="activeClicked" exact={true}>
              <CDBSidebarMenuItem>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <IconHome />
                  <span style={{ marginLeft: '10px' }}>Home</span>
                </div>
              </CDBSidebarMenuItem>
            </NavLink>
            <NavLink to="/Cutting1D" activeClassName="activeClicked" exact={true}>
              <CDBSidebarMenuItem>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <IconLinear />
                  <span style={{ marginLeft: '10px' }}>Linear Cutting</span>
                </div>
              </CDBSidebarMenuItem>
            </NavLink>



            <NavLink to="/Cutting2D" activeClassName="activeClicked" exact={true}>
              <CDBSidebarMenuItem>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <IconBoard />
                  <span style={{ marginLeft: '10px' }}>Board Cutting</span>
                </div>
              </CDBSidebarMenuItem>
            </NavLink>


            <NavLink to="/PHPTestCall" activeClassName="activeClicked" exact={true}>
              <CDBSidebarMenuItem>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <IconBoard />
                  <span style={{ marginLeft: '10px' }}>PHP Test</span>
                </div>
              </CDBSidebarMenuItem>
            </NavLink>


            <NavLink to="/PHPData" activeClassName="activeClicked" exact={true}>
              <CDBSidebarMenuItem>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <IconBoard />
                  <span style={{ marginLeft: '10px' }}>PHP Data</span>
                </div>
              </CDBSidebarMenuItem>
            </NavLink>




          </CDBSidebarMenu>
        </CDBSidebarContent>

        <CDBSidebarFooter style={{ textAlign: 'center' }}>
          <div style={{ padding: '20px 5px' }}>
            AMZ Footer
          </div>
        </CDBSidebarFooter>
      </CDBSidebar>
    </div>
  );
};

export default Sidebar;
